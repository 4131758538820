<template>
    <div class="sims-simulator-wrapper">
        <h1>SIMS Simulator</h1>

        <div class="inside">
            <!-- LA Number Editor-->
            <div class="la-number">
                <div style="width: 200px; margin-right: 10px; display: inline-block; text-align: left;">
                    <label for="">LA Number</label>
                    <a-input v-model="LANumber" placeholder="LA Number" />
                </div>
                <div style="width: 200px; margin-right: 10px; display: inline-block; text-align: left;">
                    <label for="">School Number</label>
                    <a-input v-model="SchoolNumber" placeholder="School Number" style="width: 200px;" />
                </div>
                <div style="width: 200px; margin-right: 10px; display: inline-block; text-align: left;">
                    <label for="">Currently Logged In</label>
                    <a-input :value="$oidc.isAuthenticated ? 'Yes' : 'No'" disabled />
                </div>
            </div>
            <!-- / LA Number Editor-->

            <a-row :gutter="60">
                <a-col :span="12">
                    <h2>Marketing Plugin</h2>

                    <a-button v-for="link in marketingPluginLinks" :key="`marketing-plugin-link-${link.id}`"
                        @click.prevent="navigateToLink(link.link)" type="primary" block size="large">
                        {{ link.label }}
                    </a-button>
                </a-col>
                <a-col :span="12">
                    <h2>Live Plugin</h2>

                    <a-button v-for="link in livePluginLinks" :key="`live-plugin-link-${link.id}`"
                        @click.prevent="navigateToLink(link.link)" type="primary" block size="large">
                        {{ link.label }}
                    </a-button>
                </a-col>
            </a-row>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            LANumber: '703',
            SchoolNumber: '9522',

            marketingPluginLinks: [
                {
                    label: 'Focus',
                    // link: '/sims?MenuItem=Team%20SOS&PartnerLinkUniqueID=9c10637c-3bb7-4bc0-afa8-72c6889ed191&SIMSUserExternalID=88476049-095a-404f-8f2d-c9c6b41e7d4e&LANumber=7033&SchoolNumber=9522&'
                    link: '/sims?MenuItem=*NEW*%20teamSOS&PartnerLinkUniqueID=9c10637c-3bb7-4bc0-afa8-72c6889ed191&SIMSUserExternalID=88476049-095a-404f-8f2d-c9c6b41e7d4e&LANumber=703&SchoolNumber=9522&'
                },
                {
                    label: 'Pupil - What Is...?',
                    // link: '/sims?StudentID=20882&MenuItem=What%20is%20teamSOS?&PartnerLinkUniqueID=0743ab6e-95a5-4401-8d85-a2cc549374c7&SIMSUserExternalID=88476049-095a-404f-8f2d-c9c6b41e7d4e&LANumber=7032&SchoolNumber=9522&'
                    link: '/sims?StudentID=20882&MenuItem=What%20is%20teamSOS?&PartnerLinkUniqueID=0743ab6e-95a5-4401-8d85-a2cc549374c7&SIMSUserExternalID=88476049-095a-404f-8f2d-c9c6b41e7d4e&LANumber=703&SchoolNumber=9522&'
                },
                {
                    label: 'Pupil - Success Stories',
                    // link: '/sims?StudentID=20882&MenuItem=Success%20stories&PartnerLinkUniqueID=0743ab6e-95a5-4401-8d85-a2cc549374c7&SIMSUserExternalID=88476049-095a-404f-8f2d-c9c6b41e7d4e&LANumber=7032&SchoolNumber=9522&'
                    link: '/sims?StudentID=20882&MenuItem=Success%20stories&PartnerLinkUniqueID=0743ab6e-95a5-4401-8d85-a2cc549374c7&SIMSUserExternalID=88476049-095a-404f-8f2d-c9c6b41e7d4e&LANumber=703&SchoolNumber=9522&'
                },
                {
                    label: 'Staff - What Is...?',
                    // link: '/sims?StaffID=26&MenuItem=What%20is%20teamSOS?&PartnerLinkUniqueID=6eae9184-01b5-4acf-8d70-83e59c62e022&SIMSUserExternalID=88476049-095a-404f-8f2d-c9c6b41e7d4e&LANumber=7032&SchoolNumber=9522&'
                    link: '/sims?StaffID=26&MenuItem=What%20is%20teamSOS?&PartnerLinkUniqueID=6eae9184-01b5-4acf-8d70-83e59c62e022&SIMSUserExternalID=88476049-095a-404f-8f2d-c9c6b41e7d4e&LANumber=703&SchoolNumber=9522&'
                },
                {
                    label: 'Staff - Success Stories',
                    // link: '/sims?StaffID=26&MenuItem=Success%20stories&PartnerLinkUniqueID=6eae9184-01b5-4acf-8d70-83e59c62e022&SIMSUserExternalID=88476049-095a-404f-8f2d-c9c6b41e7d4e&LANumber=7032&SchoolNumber=9522&'
                    link: '/sims?StaffID=26&MenuItem=Success%20stories&PartnerLinkUniqueID=6eae9184-01b5-4acf-8d70-83e59c62e022&SIMSUserExternalID=88476049-095a-404f-8f2d-c9c6b41e7d4e&LANumber=703&SchoolNumber=9522&'
                },
                {
                    label: 'Take Register - What Is...?',
                    // link: '/sims?EventInstanceID=117020&MenuItem=What%20is%20teamSOS?&BaseGroupID=383&SelectedStudent=25537&PartnerLinkUniqueID=f0e7e581-2c0f-4aa5-ba26-80e0dfd31169&SIMSUserExternalID=88476049-095a-404f-8f2d-c9c6b41e7d4e&LANumber=7032&SchoolNumber=9522&'
                    link: '/sims?EventInstanceID=117020&MenuItem=What%20is%20teamSOS?&BaseGroupID=383&SelectedStudent=25537&PartnerLinkUniqueID=f0e7e581-2c0f-4aa5-ba26-80e0dfd31169&SIMSUserExternalID=88476049-095a-404f-8f2d-c9c6b41e7d4e&LANumber=703&SchoolNumber=9522&'
                },
                {
                    label: 'Take Register - Success Stories',
                    // link: '/sims?EventInstanceID=117020&MenuItem=Success%20stories&BaseGroupID=383&SelectedStudent=25537&PartnerLinkUniqueID=f0e7e581-2c0f-4aa5-ba26-80e0dfd31169&SIMSUserExternalID=88476049-095a-404f-8f2d-c9c6b41e7d4e&LANumber=7032&SchoolNumber=9522&'
                    link: '/sims?EventInstanceID=117020&MenuItem=Success%20stories&BaseGroupID=383&SelectedStudent=25537&PartnerLinkUniqueID=f0e7e581-2c0f-4aa5-ba26-80e0dfd31169&SIMSUserExternalID=88476049-095a-404f-8f2d-c9c6b41e7d4e&LANumber=703&SchoolNumber=9522&'
                }
            ],

            livePluginLinks: [
                {
                    label: 'Focus',
                    // link: '/sims?MenuItem=teamSOS&PartnerLinkUniqueID=99a669d9-5f0e-4043-ab09-1a10b9671084&SIMSUserExternalID=88476049-095a-404f-8f2d-c9c6b41e7d4e&LANumber=7032&SchoolNumber=9522&'
                    link: '/sims?MenuItem=teamSOS&PartnerLinkUniqueID=99a669d9-5f0e-4043-ab09-1a10b9671084&SIMSUserExternalID=88476049-095a-404f-8f2d-c9c6b41e7d4e&LANumber=703&SchoolNumber=9522&'
                },
                {
                    label: 'Call For Help',
                    // link: '/sims?StudentID=23670&MenuItem=Call%20for%20help&PartnerLinkUniqueID=d7a6164c-1a1a-4907-a8d5-3c6bc05ab7d6&SIMSUserExternalID=88476049-095a-404f-8f2d-c9c6b41e7d4e&LANumber=703&SchoolNumber=9522&'
                    link: '/sims?StudentID=23670&MenuItem=Call%20for%20help&PartnerLinkUniqueID=d7a6164c-1a1a-4907-a8d5-3c6bc05ab7d6&SIMSUserExternalID=88476049-095a-404f-8f2d-c9c6b41e7d4e&LANumber=703&SchoolNumber=9522&'
                },
                {
                    label: 'Active Incidents',
                    // link: '/sims?StudentID=23670&MenuItem=Active%20Incidents&PartnerLinkUniqueID=d7a6164c-1a1a-4907-a8d5-3c6bc05ab7d6&SIMSUserExternalID=88476049-095a-404f-8f2d-c9c6b41e7d4e&LANumber=703&SchoolNumber=9522&'
                    link: '/sims?StudentID=23670&MenuItem=Active%20Incidents&PartnerLinkUniqueID=d7a6164c-1a1a-4907-a8d5-3c6bc05ab7d6&SIMSUserExternalID=88476049-095a-404f-8f2d-c9c6b41e7d4e&LANumber=703&SchoolNumber=9522&'
                },
                {
                    label: 'Chat',
                    // link: '/sims?StudentID=23670&MenuItem=Chat&PartnerLinkUniqueID=d7a6164c-1a1a-4907-a8d5-3c6bc05ab7d6&SIMSUserExternalID=88476049-095a-404f-8f2d-c9c6b41e7d4e&LANumber=703&SchoolNumber=9522&'
                    link: '/sims?StudentID=23670&MenuItem=Chat&PartnerLinkUniqueID=d7a6164c-1a1a-4907-a8d5-3c6bc05ab7d6&SIMSUserExternalID=88476049-095a-404f-8f2d-c9c6b41e7d4e&LANumber=703&SchoolNumber=9522&'
                },
                {
                    label: 'Guidance',
                    // link: '/sims?StudentID=23670&MenuItem=Guidance&PartnerLinkUniqueID=d7a6164c-1a1a-4907-a8d5-3c6bc05ab7d6&SIMSUserExternalID=88476049-095a-404f-8f2d-c9c6b41e7d4e&LANumber=703&SchoolNumber=9522&'
                    link: '/sims?StudentID=23670&MenuItem=Guidance&PartnerLinkUniqueID=d7a6164c-1a1a-4907-a8d5-3c6bc05ab7d6&SIMSUserExternalID=88476049-095a-404f-8f2d-c9c6b41e7d4e&LANumber=703&SchoolNumber=9522&'
                },
                {
                    label: 'Staff - Call For Help',
                    // link: '/sims?StaffID=26&MenuItem=Call%20for%20help&PartnerLinkUniqueID=e85185ce-ebf5-440a-9235-94af6766d792&SIMSUserExternalID=88476049-095a-404f-8f2d-c9c6b41e7d4e&LANumber=703&SchoolNumber=9522&'
                    link: '/sims?StaffID=26&MenuItem=Call%20for%20help&PartnerLinkUniqueID=e85185ce-ebf5-440a-9235-94af6766d792&SIMSUserExternalID=88476049-095a-404f-8f2d-c9c6b41e7d4e&LANumber=703&SchoolNumber=9522&'
                },
                {
                    label: 'Staff - Active Incidents',
                    // link: '/sims?StaffID=26&MenuItem=Active%20Incidents&PartnerLinkUniqueID=e85185ce-ebf5-440a-9235-94af6766d792&SIMSUserExternalID=88476049-095a-404f-8f2d-c9c6b41e7d4e&LANumber=703&SchoolNumber=9522&'
                    link: '/sims?StaffID=26&MenuItem=Active%20Incidents&PartnerLinkUniqueID=e85185ce-ebf5-440a-9235-94af6766d792&SIMSUserExternalID=88476049-095a-404f-8f2d-c9c6b41e7d4e&LANumber=703&SchoolNumber=9522&'
                },
                {
                    label: 'Staff - Chat',
                    // link: '/sims?StaffID=26&MenuItem=Chat&PartnerLinkUniqueID=e85185ce-ebf5-440a-9235-94af6766d792&SIMSUserExternalID=88476049-095a-404f-8f2d-c9c6b41e7d4e&LANumber=703&SchoolNumber=9522&'
                    link: '/sims?StaffID=26&MenuItem=Chat&PartnerLinkUniqueID=e85185ce-ebf5-440a-9235-94af6766d792&SIMSUserExternalID=88476049-095a-404f-8f2d-c9c6b41e7d4e&LANumber=703&SchoolNumber=9522&'
                },
                {
                    label: 'Staff - Guidance',
                    // link: '/sims?StaffID=26&MenuItem=Guidance&PartnerLinkUniqueID=e85185ce-ebf5-440a-9235-94af6766d792&SIMSUserExternalID=88476049-095a-404f-8f2d-c9c6b41e7d4e&LANumber=703&SchoolNumber=9522&'
                    link: '/sims?StaffID=26&MenuItem=Guidance&PartnerLinkUniqueID=e85185ce-ebf5-440a-9235-94af6766d792&SIMSUserExternalID=88476049-095a-404f-8f2d-c9c6b41e7d4e&LANumber=703&SchoolNumber=9522&'
                },
                {
                    label: 'Attendance - Call For Help',
                    // link: '/sims?EventInstanceID=117020&MenuItem=Call%20for%20help&BaseGroupID=383&SelectedStudent=25537&PartnerLinkUniqueID=e456a8d8-7cfa-46b4-9f72-03cc7df89da6&SIMSUserExternalID=88476049-095a-404f-8f2d-c9c6b41e7d4e&LANumber=703&SchoolNumber=9522&'
                    link: '/sims?EventInstanceID=117020&MenuItem=Call%20for%20help&BaseGroupID=383&SelectedStudent=25537&PartnerLinkUniqueID=e456a8d8-7cfa-46b4-9f72-03cc7df89da6&SIMSUserExternalID=88476049-095a-404f-8f2d-c9c6b41e7d4e&LANumber=703&SchoolNumber=9522&'
                },
                {
                    label: 'Attendance - Active Incidents',
                    // link: '/sims?EventInstanceID=117020&MenuItem=Active%20Incidents&BaseGroupID=383&SelectedStudent=25537&PartnerLinkUniqueID=e456a8d8-7cfa-46b4-9f72-03cc7df89da6&SIMSUserExternalID=88476049-095a-404f-8f2d-c9c6b41e7d4e&LANumber=703&SchoolNumber=9522&'
                    link: '/sims?EventInstanceID=117020&MenuItem=Active%20Incidents&BaseGroupID=383&SelectedStudent=25537&PartnerLinkUniqueID=e456a8d8-7cfa-46b4-9f72-03cc7df89da6&SIMSUserExternalID=88476049-095a-404f-8f2d-c9c6b41e7d4e&LANumber=703&SchoolNumber=9522&'
                },
                {
                    label: 'Attendance - Chat',
                    // link: '/sims?EventInstanceID=117020&MenuItem=Chat&BaseGroupID=383&SelectedStudent=25537&PartnerLinkUniqueID=e456a8d8-7cfa-46b4-9f72-03cc7df89da6&SIMSUserExternalID=88476049-095a-404f-8f2d-c9c6b41e7d4e&LANumber=703&SchoolNumber=9522&'
                    link: '/sims?EventInstanceID=117020&MenuItem=Chat&BaseGroupID=383&SelectedStudent=25537&PartnerLinkUniqueID=e456a8d8-7cfa-46b4-9f72-03cc7df89da6&SIMSUserExternalID=88476049-095a-404f-8f2d-c9c6b41e7d4e&LANumber=703&SchoolNumber=9522&'
                },
                {
                    label: 'Attendance - Guidance',
                    // link: '/sims?EventInstanceID=117020&MenuItem=Guidance&BaseGroupID=383&SelectedStudent=25537&PartnerLinkUniqueID=e456a8d8-7cfa-46b4-9f72-03cc7df89da6&SIMSUserExternalID=88476049-095a-404f-8f2d-c9c6b41e7d4e&LANumber=703&SchoolNumber=9522&'
                    link: '/sims?EventInstanceID=117020&MenuItem=Guidance&BaseGroupID=383&SelectedStudent=25537&PartnerLinkUniqueID=e456a8d8-7cfa-46b4-9f72-03cc7df89da6&SIMSUserExternalID=88476049-095a-404f-8f2d-c9c6b41e7d4e&LANumber=703&SchoolNumber=9522&'
                }
            ]
        }
    },

    created() {
        // Your original data
        const originalData = this.livePluginLinks;

        // Initialize an empty array to store the formatted data
        const formattedData = [];

        // Loop through the original data and extract the 'guid' and 'description'
        originalData.forEach(item => {
            // Extract 'guid' from the link using regex
            const guidMatch = item.link.match(/PartnerLinkUniqueID=([^&]+)/);
            const guid = guidMatch ? guidMatch[1] : null;

            // Extract MenuItem
            const menuItemMatch = item.link.match(/MenuItem=([^&]+)/);
            // Convert into human readable format
            const menuItem = menuItemMatch ? menuItemMatch[1].replace(/%20/g, ' ') : null;

            // Construct the 'description' by combining the label with additional text
            const description = `Live - ${item.label}`;

            // Create the object in the desired format
            const formattedItem = {
                "guid": guid,
                "menuItem": menuItem,
                "description": description,
            };

            // Push the formatted item to the formattedData array
            formattedData.push(formattedItem);
        });

        // Now, formattedData contains the data in the desired format
        console.log(formattedData);
    },

    methods: {
        navigateToLink(link) {
            // this.$router.push(link);

            // Replace LA Number with the one from the input
            link = link.replace(/LANumber=[^&]+/, `LANumber=${this.LANumber}`);

            // Replace School Number with the one from the input
            link = link.replace(/SchoolNumber=[^&]+/, `SchoolNumber=${this.SchoolNumber}`);

            window.open(link, '_blank');
        }
    }
}
</script>

<style scoped lang="scss">
.sims-simulator-wrapper {
    min-height: 100vh !important;
    height: 100%;
    width: 100%;
    padding: 3em;
    overflow-y: scroll;
    text-align: center;

    .la-number {
        margin-bottom: 40px;
    }

    h1 {
        font-size: 2em;
    }

    .inside {
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 30px;

        .ant-btn {
            margin-top: 15px;
        }
    }
}
</style>